<template>
    <div>
        <div v-if="!loading" class="rec-data-div" :class="pageMeta.component_name.toLowerCase() + '-page'">
            <form @submit.prevent="updateRecord">
                <div class="flex space-between">
                    <div class="name-id"><FormClientFull :client_id="client_id" @getClient="getClient" /><br /></div>
                    <div class="align-right">
                        <FormCompany @getCompany="getCompany" />
                    </div>
                </div>

                <button
                    type="button"
                    @click="signature_ids = []"
                    v-if="signature_ids.length && original_signature_ids.length"
                    class="do-not-print-me"
                >
                    Edit & Create Addendum
                </button>
                <button
                    v-if="original_signature_ids.length && !signature_ids.length"
                    class="align-right"
                    type="button"
                    @click="signature_ids = original_signature_ids"
                >
                    Cancel Edit
                </button>
                <div class="dark-text header-w-bg addendum" v-if="original_signature_ids.length && !signature_ids.length">
                    You are now editing the original signed document to create an addendum
                </div>

                <hr class="m-15" />

                <!-- put generated code BELOW here -->

                <!-- TAB INPUTS -->
                <input
                    id="page-tab-1"
                    class="page-tab-input do-not-print-me"
                    name="page-tab-input"
                    type="radio"
                    checked
                    @click="changeTab"
                />
                <input id="page-tab-2" class="page-tab-input do-not-print-me" name="page-tab-input" type="radio" @click="changeTab" />
                <input id="page-tab-3" class="page-tab-input do-not-print-me" name="page-tab-input" type="radio" @click="changeTab" />
                <input id="page-tab-4" class="page-tab-input do-not-print-me" name="page-tab-input" type="radio" @click="changeTab" />
                <input id="page-tab-5" class="page-tab-input do-not-print-me" name="page-tab-input" type="radio" @click="changeTab" />
                <input id="page-tab-6" class="page-tab-input do-not-print-me" name="page-tab-input" type="radio" @click="changeTab" />
                <input id="page-tab-7" class="page-tab-input do-not-print-me" name="page-tab-input" type="radio" @click="changeTab" />
                <input id="page-tab-8" class="page-tab-input do-not-print-me" name="page-tab-input" type="radio" @click="changeTab" />
                <input id="page-tab-9" class="page-tab-input do-not-print-me" name="page-tab-input" type="radio" @click="changeTab" />

                <!-- TABS CONTAINER -->
                <div class="company-settings-header page-tabs-container">
                    <h1>Biopsychosocial Assessment</h1>

                    <div v-if="!dateEditing">
                        {{ formattedDate }}
                        <span class="material-icons-outlined pointer" @click.prevent="toggleDateEditing">edit</span>
                    </div>
                    <div class="flex center max500 top-10 bottom-20" v-else>
                        <input class="flex-1 right-10" type="date" v-model="dateDate" />
                        <input class="flex-1 right-10" type="time" v-model="dateTime" />
                        <button @click.prevent="toggleDateEditing">Done</button>
                    </div>
                    <hr class="top-5 bottom-20" />
                    <div class="flex space-between do-not-print-me">
                        <div class="flex center wrap">
                            <template class="do-not-print-me">
                                <label class="page-tab page-tab-1 do-not-print-me" for="page-tab-1">Section 1</label>
                                <label class="page-tab page-tab-2 do-not-print-me" for="page-tab-2">Section 2</label>
                                <label class="page-tab page-tab-3 do-not-print-me" for="page-tab-3">Section 3</label>
                                <label class="page-tab page-tab-4 do-not-print-me" for="page-tab-4">Section 4</label>
                                <label class="page-tab page-tab-5 do-not-print-me" for="page-tab-5">Section 5</label>
                                <label class="page-tab page-tab-6 do-not-print-me" for="page-tab-6">Section 6</label>
                                <label class="page-tab page-tab-7 do-not-print-me" for="page-tab-7">Section 7</label>
                                <label class="page-tab page-tab-8 do-not-print-me" for="page-tab-8">Section 8</label>
                                <label class="page-tab page-tab-9 do-not-print-me" for="page-tab-9">Section 9</label>
                            </template>
                        </div>
                        <div class="align-right do-not-print-me">
                            <button class="secondary nowrap" type="button" @click="printView()">Print View</button>
                        </div>
                    </div>
                </div>

                <!-- TABBED PAGE SECTIONS -->
                <section class="tabbed-page form_section_1 " id="page-tab-1" v-disable-all="!pageMeta.permissions.edit">
                    <h2>Demographics & Presenting Problem</h2>
                    <h3>General Biopsychosocial Assessment Information</h3>
                    <div class="flex space-between colx3 bottom-20">
                        <div>
                            <label for="assessor_id">Assessment Completed By</label>
                            <select v-model="rec.assessor_id" id="assessor_id">
                                <option
                                    v-for="counselor in counselorOptions"
                                    :value="counselor.value"
                                    :key="counselor.value"
                                    >{{ counselor.text }}</option
                                >
                            </select>
                        </div>
                        <div>
                            <label for="location">Location Completed at</label>
                            <select v-model="rec.location" id="location">
                                <option v-for="location in locationOptions" :value="location.id" :key="location.id">{{
                                    location.office_name
                                }}</option>
                            </select>
                        </div>
                        <Input type="text" v-model="rec.referral_source" id="referral_source" label="Referral Source" />
                    </div>

                    <h3>Demographics</h3>
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="current_living_situation"
                        v-model="rec.current_living_situation"
                        label="Current Living Situation"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <div class="avoid-break block">
                        <h3>Presenting Problem and Symptoms</h3>
                        <ExpandableTextArea
                            class="avoid-break block"
                            inputClass="block do-not-print-me"
                            oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                            type="text"
                            id="presenting_problem"
                            v-model="rec.presenting_problem"
                            label="Presenting Problem"
                            :limit=500
                            inputStyle="min-height:20px;"
                        />
                    </div>
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="symptoms"
                        v-model="rec.symptoms"
                        label="Symptoms"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <div class="flex colx2 space-between avoid-break">
                        <ExpandableTextArea
                            class="avoid-break block"
                            inputClass="block do-not-print-me"
                            oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                            type="text"
                            id="duration_of_symptoms"
                            v-model="rec.duration_of_symptoms"
                            label="Duration of Symptoms"
                            :limit=500
                            inputStyle="min-height:20px;"
                        />
                        <ExpandableTextArea
                            class="avoid-break block"
                            inputClass="block do-not-print-me"
                            oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                            type="text"
                            id="intensity_of_symptoms"
                            v-model="rec.intensity_of_symptoms"
                            label="Intensity of Symptoms"
                            :limit=500
                            inputStyle="min-height:20px;"
                        />
                    </div>
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="effect_on_functioning"
                        v-model="rec.effect_on_functioning"
                        label="Effect on Functioning"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                </section>
                <section class="tabbed-page form_section_2" id="page-tab-2" v-disable-all="!pageMeta.permissions.edit">
                    <h2>Development, Family & Sexual History</h2>
                    <h3>Development</h3>

                    <div class="flex bottom-10 colx2 avoid-break">
                        <Input class="flex-1 right-15 bottom-30"
                            type="text" id="place_of_birth" v-model="rec.place_of_birth" label="Place of Birth" />
                        <ExpandableTextArea
                            class="avoid-break block"
                            inputClass="block do-not-print-me"
                            oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                            type="text"
                            id="complications_during_birth"
                            v-model="rec.complications_during_birth"
                            label="Complications During Birth"
                            :limit=500
                            inputStyle="min-height:20px;"
                        />
                    </div>
                    <div>
                        <ExpandableTextArea
                            class="avoid-break block"
                            inputClass="block do-not-print-me"
                            oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                            type="text"
                            id="mothers_substance_use_during_pregnancy"
                            v-model="rec.mothers_substance_use_during_pregnancy"
                            label="Mother’s Substance Use During Pregnancy (includes caﬀeine, nicotine, medication, other drugs, alcohol)"
                            :limit=500
                            inputStyle="min-height:20px;"
                        />
                        <ExpandableTextArea
                            class="avoid-break block"
                            inputClass="block do-not-print-me"
                            oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                            type="text"
                            id="delays_or_diﬃculties_during_early_childhood"
                            v-model="rec.delays_or_diﬃculties_during_early_childhood"
                            label="Delays or Diﬃculties During Early Childhood"
                            :limit=500
                            inputStyle="min-height:20px;"
                        />
                        <ExpandableTextArea
                            class="avoid-break block"
                            inputClass="block do-not-print-me"
                            oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                            type="text"
                            id="medical_problems_or_serious_injury_during_childhood"
                            v-model="rec.medical_problems_or_serious_injury_during_childhood"
                            label="Medical Problems or Serious Injury During Childhood"
                            :limit=500
                            inputStyle="min-height:20px;"
                        />
                        <ExpandableTextArea
                            class="avoid-break block"
                            inputClass="block do-not-print-me"
                            oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                            type="text"
                            id="development_comments"
                            v-model="rec.development_comments"
                            label="Development Comments"
                            :limit=500
                            inputStyle="min-height:20px;"
                        />
                    </div>
                    <div class="avoid-break">
                        <h3>Family History</h3>
                        <ExpandableTextArea
                            class="avoid-break block"
                            inputClass="block do-not-print-me"
                            oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                            type="text"
                            id="children"
                            v-model="rec.children"
                            label="Children (include names, ages)"
                            :limit=500
                            inputStyle="min-height:20px;"
                        />
                    </div>
                    <div class="flex colx2 space-between bottom-10 avoid-break">
                        <ExpandableTextArea
                            class="avoid-break block"
                            inputClass="block do-not-print-me"
                            oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                            type="text"
                            id="children_reside_with"
                            v-model="rec.children_reside_with"
                            label="Children Reside With"
                            :limit=500
                            inputStyle="min-height:20px;"
                        />
                        <ExpandableTextArea
                            class="avoid-break block"
                            inputClass="block do-not-print-me"
                            oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                            type="text"
                            id="state_involvement_regarding_the_children"
                            v-model="rec.state_involvement_regarding_the_children"
                            label="State Involvement Regarding the Children"
                            :limit=500
                            inputStyle="min-height:20px;"
                        />
                    </div>
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="additional_individuals_in_the_residence"
                        v-model="rec.additional_individuals_in_the_residence"
                        label="Additional Individuals in the Residence (include ages, relationship)"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="relationship_with_immediate_family"
                        v-model="rec.relationship_with_immediate_family"
                        label="Relationship with Immediate Family"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                        <div class="flex colx2 space-between bottom-10 avoid-break">
                            <ExpandableTextArea
                                class="avoid-break block"
                                inputClass="block do-not-print-me"
                                oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                                type="text"
                                id="fathers_information"
                                v-model="rec.fathers_information"
                                label="Father’s Information (living or deceased, age)"
                                :limit=500
                                inputStyle="min-height:20px;"
                            />
                            <ExpandableTextArea
                                class="avoid-break block"
                                inputClass="block do-not-print-me"
                                oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                                type="text"
                                id="current_relationship_with_father"
                                v-model="rec.current_relationship_with_father"
                                label="Current Relationship with Father"
                                :limit=500
                                inputStyle="min-height:20px;"
                            />
                        </div>
                        <div class="flex colx2 space-between bottom-10 avoid-break">
                            <ExpandableTextArea
                                class="avoid-break block"
                                inputClass="block do-not-print-me"
                                oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                                type="text"
                                id="mothers_information"
                                v-model="rec.mothers_information"
                                label="Mother’s Information (living or deceased, age)"
                                :limit=500
                                inputStyle="min-height:20px;"
                            />
                            <ExpandableTextArea
                                class="avoid-break block"
                                inputClass="block do-not-print-me"
                                oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                                type="text"
                                id="current_relationship_with_mother"
                                v-model="rec.current_relationship_with_mother"
                                label="Current Relationship with Mother"
                                :limit=500
                                inputStyle="min-height:20px;"
                            />
                        </div>
                        <div class="flex colx2 space-between bottom-10 avoid-break">
                            <ExpandableTextArea
                                class="avoid-break block"
                                inputClass="block do-not-print-me"
                                oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                                type="text"
                                id="step_parents_information"
                                v-model="rec.step_parents_information"
                                label="Step Parent(s) Information (living or deceased, age)"
                                :limit=500
                                inputStyle="min-height:20px;"
                            />
                            <ExpandableTextArea
                                class="avoid-break block"
                                inputClass="block do-not-print-me"
                                oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                                type="text"
                                id="current_relationship_with_step_parents"
                                v-model="rec.current_relationship_with_step_parents"
                                label="Current Relationship with Step Parent(s)"
                                :limit=500
                                inputStyle="min-height:20px;"
                            />
                        </div>
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="parents_current_relationship"
                        v-model="rec.parents_current_relationship"
                        label="Parent’s (including step parents) Current Relationship"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="sibling_information"
                        v-model="rec.sibling_information"
                        label="Sibling Information (include ages)"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="current_relationship_with_siblings"
                        v-model="rec.current_relationship_with_siblings"
                        label="Current Relationship with Siblings"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="describe_your_childhood"
                        v-model="rec.describe_your_childhood"
                        label="Describe Your Childhood"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="family_history_of_mental_illness"
                        v-model="rec.family_history_of_mental_illness"
                        label="Family History of Mental Illness"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="family_history_of_substance_abuse"
                        v-model="rec.family_history_of_substance_abuse"
                        label="Family History of Substance Abuse"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="physical_sexual_or_emotional_abuse_experienced_or_observed"
                        v-model="rec.physical_sexual_or_emotional_abuse_experienced_or_observed"
                        label="Describe Any Physical, Sexual or Emotional Abuse Experienced or Observed"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="family_history_comments"
                        v-model="rec.family_history_comments"
                        label="Family History Comments"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />

                    <div class="avoid-break">
                        <h3>Sexual History</h3>
                        <div class="flex bottom-10 colx2 avoid-break">
                            <Dropdown
                                class="flex-1 bottom-30 right-15"
                                :options="opts.sexual_orientation"
                                v-model="rec.sexual_orientation"
                                label="Sexual Orientation"
                                id="sexual_orientation"
                            />
                            <ExpandableTextArea
                                class="avoid-break block"
                                inputClass="block do-not-print-me"
                                oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                                type="text"
                                id="how_did_client_learn_about_sex"
                                v-model="rec.how_did_client_learn_about_sex"
                                label="How Did Client Learn About Sex"
                                :limit=500
                                inputStyle="min-height:20px;"
                            />
                        </div>
                    </div>

                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="views_about_sex"
                        v-model="rec.views_about_sex"
                        label="Views About Sex"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="impact_of_substance_use_on_sex_life"
                        v-model="rec.impact_of_substance_use_on_sex_life"
                        label="Impact of Substance Use on Sex Life"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="past_or_current_sexual_concerns"
                        v-model="rec.past_or_current_sexual_concerns"
                        label="Past or Current Sexual Concerns"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="sexual_history_comments"
                        v-model="rec.sexual_history_comments"
                        label="Sexual History Comments"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                </section>
                <section class="tabbed-page form_section_3" id="page-tab-3" v-disable-all="!pageMeta.permissions.edit">
                    <div class="avoid-break">
                        <h2>Education & Employment</h2>
                        <h3>Education</h3>
                        <div class="flex bottom-10 avoid-break">
                            <Dropdown
                                class="flex-1 right-15"
                                id="highest_level_of_education"
                                v-model="rec.highest_level_of_education"
                                label="Highest Level of Education"
                                :options="opts.highest_level_of_education"
                            />
                            <Input
                                class="flex-3"
                                type="text"
                                id="current_school"
                                v-model="rec.current_school"
                                label="If Still Attending, List Current School"
                            />
                        </div>
                    </div>
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="experience_in_school"
                        v-model="rec.experience_in_school"
                        label="Client's Experience in School"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="impact_of_substance_use_on_education"
                        v-model="rec.impact_of_substance_use_on_education"
                        label="Impact of Substance Use on Education"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="education_comments"
                        v-model="rec.education_comments"
                        label="Education Comments"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />

                    <div class="avoid-break">
                        <h3>Employment</h3>
                        <div class="flex bottom bottom-10 avoid-break">
                            <Dropdown
                                class="flex-1 right-15"
                                id="employment_status"
                                v-model="rec.employment_status"
                                label="Employment Status"
                                :options="opts.employment_status"
                            />
                            <Input
                                class="flex-1"
                                type="text"
                                id="field_employed_in"
                                v-model="rec.field_employed_in"
                                label="Field Employed In"
                            />
                        </div>
                    </div>
                    <div class="flex bottom bottom-10 avoid-break">
                        <Input
                            class="flex-1 right-15"
                            type="text"
                            id="length_of_this_employment"
                            v-model="rec.length_of_this_employment"
                            label="Length of this Employment"
                        />
                        <Input
                            class="flex-1"
                            type="text"
                            id="satisfaction_with_employment"
                            v-model="rec.satisfaction_with_employment"
                            label="Satisfaction with Employment"
                        />
                    </div>
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="describe_employment_history"
                        v-model="rec.describe_employment_history"
                        label="Describe Employment History"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="impact_of_substance_use_on_employment"
                        v-model="rec.impact_of_substance_use_on_employment"
                        label="Impact of Substance Use on Employment"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="sources_of_income"
                        v-model="rec.sources_of_income"
                        label="Source(s) of Income"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="current_or_past_public_assistance"
                        v-model="rec.current_or_past_public_assistance"
                        label="Current or Past Public Assistance"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="concerns_about_finances"
                        v-model="rec.concerns_about_finances"
                        label="Concerns About Finances"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="transportation_access"
                        v-model="rec.transportation_access"
                        label="Transportation Access"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="employment_or_finance_comments"
                        v-model="rec.employment_or_finance_comments"
                        label="Employment or Finance Comments"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                </section>
                <section class="tabbed-page form_section_4" id="page-tab-4" v-disable-all="!pageMeta.permissions.edit">
                    <div class="avoid-break">
                        <h2>Legal & Medical</h2>
                        <h3>Legal</h3>
                        <ExpandableTextArea
                            class="avoid-break block"
                            inputClass="block do-not-print-me"
                            oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                            type="text"
                            id="arrest_history"
                            v-model="rec.arrest_history"
                            label="Arrest History (include charges, dates, outcomes)"
                            :limit=500
                            inputStyle="min-height:20px;"
                        />
                    </div>
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="details_of_any_current_legal_charges"
                        v-model="rec.details_of_any_current_legal_charges"
                        label="Details of Any Current Legal Charges"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="probation_status"
                        v-model="rec.probation_status"
                        label="Probation Status"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="impact_of_substance_use_on_legal_history"
                        v-model="rec.impact_of_substance_use_on_legal_history"
                        label="Impact of Substance Use on Legal History"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="legal_comments"
                        v-model="rec.legal_comments"
                        label="Legal Comments"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />

                    <div class="avoid-break">
                        <h3>Medical</h3>
                        <ExpandableTextArea
                            class="avoid-break block"
                            inputClass="block do-not-print-me"
                            oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                            type="text"
                            id="current_health_as_described_by_client"
                            v-model="rec.current_health_as_described_by_client"
                            label="Current Health as Described by Client"
                            :limit=500
                            inputStyle="min-height:20px;"
                        />
                    </div>
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="current_medical_conditions"
                        v-model="rec.current_medical_conditions"
                        label="Current Medical Conditions"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="current_medications"
                        v-model="rec.current_medications"
                        label="Current Medications (include dosage, frequency, prescriber)"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="past_medications"
                        v-model="rec.past_medications"
                        label="Past Medications"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="previous_hospitalizations_or_surgeries"
                        v-model="rec.previous_hospitalizations_or_surgeries"
                        label="Previous Hospitalizations or Surgeries"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="allergies"
                        v-model="rec.allergies"
                        label="Allergies"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <div class="flex bottom bottom-10 avoid-break">
                        <Input
                            class="flex-1 right-15"
                            type="text"
                            id="approximate_date_of_last_physical_exam"
                            v-model="rec.approximate_date_of_last_physical_exam"
                            label="Approximate Date of Last Physical Exam"
                        />
                        <Input
                            class="flex-1 right-15"
                            type="text"
                            id="primary_care_physician"
                            v-model="rec.primary_care_physician"
                            label="Primary Care Physician"
                        />
                        <Input
                            class="flex-1"
                            type="text"
                            id="health_insurance_coverage"
                            v-model="rec.health_insurance_coverage"
                            label="Health Insurance Coverage"
                        />
                    </div>
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="description_of_sleeping_habits"
                        v-model="rec.description_of_sleeping_habits"
                        label="Description of Sleeping Habits"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="description_of_eating_habits"
                        v-model="rec.description_of_eating_habits"
                        label="Description of Eating Habits"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="level_of_physical_activity"
                        v-model="rec.level_of_physical_activity"
                        label="Level of Physical Activity"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="impact_of_substance_use_on_physical_health"
                        v-model="rec.impact_of_substance_use_on_physical_health"
                        label="Impact of Substance Use on Physical Health"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="medical_comments"
                        v-model="rec.medical_comments"
                        label="Medical Comments"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />

                </section>
                <section class="tabbed-page form_section_5" id="page-tab-5" v-disable-all="!pageMeta.permissions.edit">
                    <div class="avoid-break">
                        <h2>Mental Health History & Current</h2>
                        <h3>Mental Health</h3>
                        <ExpandableTextArea
                            class="avoid-break block"
                            inputClass="block do-not-print-me"
                            oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                            type="text"
                            id="history_of_mental_health_problems"
                            v-model="rec.history_of_mental_health_problems"
                            label="History of Mental Health Problems"
                            :limit=500
                            inputStyle="min-height:20px;"
                        />
                    </div>
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="history_of_psychotropic_medication"
                        v-model="rec.history_of_psychotropic_medication"
                        label="History of Psychotropic Medication"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="history_of_abuse_or_neglect"
                        v-model="rec.history_of_abuse_or_neglect"
                        label="History of Abuse or Neglect (include type of abuse, age, abuser)"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="history_of_adverse_life_events"
                        v-model="rec.history_of_adverse_life_events"
                        label="History of Adverse Life Events"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="history_of_suicidal_ideation"
                        v-model="rec.history_of_suicidal_ideation"
                        label="History of Suicidal Ideation"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="history_of_suicide_attempts"
                        v-model="rec.history_of_suicide_attempts"
                        label="History of Suicide Attempts"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="history_of_homicidal_ideation"
                        v-model="rec.history_of_homicidal_ideation"
                        label="History of Homicidal Ideation"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="history_of_mental_health_treatment"
                        v-model="rec.history_of_mental_health_treatment"
                        label="History of Mental Health Treatment (include provider name, dates, outcome)"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="history_of_baker_acts_or_involuntary_commitments"
                        v-model="rec.history_of_baker_acts_or_involuntary_commitments"
                        label="History of Baker Acts or Involuntary Commitments (include provider name, dates, outcomes)"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="current_mental_health_problems"
                        v-model="rec.current_mental_health_problems"
                        label="Current Mental Health Problems"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="current_psychotropics_medication"
                        v-model="rec.current_psychotropics_medication"
                        label="Current Psychotropic(s) Medication (include name, dosage, prescriber)"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="impact_of_substance_use_on_mental_health"
                        v-model="rec.impact_of_substance_use_on_mental_health"
                        label="Impact of Substance Use on Mental Health"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="mental_health_comments"
                        v-model="rec.mental_health_comments"
                        label="Mental Health Comments"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                </section>
                <section class="tabbed-page form_section_6 " id="page-tab-6" v-disable-all="!pageMeta.permissions.edit">
                    <div class="avoid-break">
                        <h2>Substance Use History & Current</h2>
                        <h3>Substance Use</h3>
                        <p class="bold">
                            Include the following Use Histories: age of first use, frequency of heaviest use, recent pattern
                            of use, date last used, and method.
                        </p>
                    </div>
                    <div class="bordered bottom-20">
                        <ExpandableTextArea
                            class="avoid-break block"
                            inputClass="block do-not-print-me"
                            oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                            type="text"
                            id="alcohol_history"
                            v-model="rec.alcohol_history"
                            label="Alcohol Use History"
                            :limit=500
                            inputStyle="min-height:20px;"
                        />
                        <ExpandableTextArea
                            class="avoid-break block"
                            inputClass="block do-not-print-me"
                            oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                            type="text"
                            id="cannabis_history"
                            v-model="rec.cannabis_history"
                            label="Cannabis Use History"
                            :limit=500
                            inputStyle="min-height:20px;"
                        />
                        <ExpandableTextArea
                            class="avoid-break block"
                            inputClass="block do-not-print-me"
                            oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                            type="text"
                            id="cocaine_history"
                            v-model="rec.cocaine_history"
                            label="Cocaine Use History"
                            :limit=500
                            inputStyle="min-height:20px;"
                        />
                        <ExpandableTextArea
                            class="avoid-break block"
                            inputClass="block do-not-print-me"
                            oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                            type="text"
                            id="stimulant_history"
                            v-model="rec.stimulant_history"
                            label="Stimulant Use History"
                            :limit=500
                            inputStyle="min-height:20px;"
                        />
                        <ExpandableTextArea
                            class="avoid-break block"
                            inputClass="block do-not-print-me"
                            oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                            type="text"
                            id="opioid_history"
                            v-model="rec.opioid_history"
                            label="Opioid Use History"
                            :limit=500
                            inputStyle="min-height:20px;"
                        />
                        <ExpandableTextArea
                            class="avoid-break block"
                            inputClass="block do-not-print-me"
                            oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                            type="text"
                            id="inhalant_history"
                            v-model="rec.inhalant_history"
                            label="Inhalant Use History"
                            :limit=500
                            inputStyle="min-height:20px;"
                        />
                        <ExpandableTextArea
                            class="avoid-break block"
                            inputClass="block do-not-print-me"
                            oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                            type="text"
                            id="hallucinogen_history"
                            v-model="rec.hallucinogen_history"
                            label="Hallucinogen Use History"
                            :limit=500
                            inputStyle="min-height:20px;"
                        />
                        <ExpandableTextArea
                            class="avoid-break block"
                            inputClass="block do-not-print-me"
                            oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                            type="text"
                            id="sedative_history"
                            v-model="rec.sedative_history"
                            label="Sedative Use History"
                            :limit=500
                            inputStyle="min-height:20px;"
                        />
                        <ExpandableTextArea
                            class="avoid-break block"
                            inputClass="block do-not-print-me"
                            oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                            type="text"
                            id="iv_drug_history"
                            v-model="rec.iv_drug_history"
                            label="IV Drug Use History"
                            :limit=500
                            inputStyle="min-height:20px;"
                        />
                        <ExpandableTextArea
                            class="avoid-break block"
                            inputClass="block do-not-print-me"
                            oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                            type="text"
                            id="other_drug_history"
                            v-model="rec.other_drug_history"
                            label="Other Drug Use History"
                            :limit=500
                            inputStyle="min-height:20px;"
                        />
                    </div>
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="substance_of_choice"
                        v-model="rec.substance_of_choice"
                        label="Substance of Choice"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="history_of_overdose"
                        v-model="rec.history_of_overdose"
                        label="History of Overdose"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="history_of_withdrawals"
                        v-model="rec.history_of_withdrawals"
                        label="History of Withdrawals"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="substance_abuse_treatment_history"
                        v-model="rec.substance_abuse_treatment_history"
                        label="Substance Abuse Treatment History (include provider name, dates, outcome)"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="longest_period_of_abstinence"
                        v-model="rec.longest_period_of_abstinence"
                        label="Longest Period of Abstinence"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="consequences_of_substance_use"
                        v-model="rec.consequences_of_substance_use"
                        label="Consequences of Substance Use"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="views_on_substance_use"
                        v-model="rec.views_on_substance_use"
                        label="Views on Substance Use"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="receptiveness_to_treatment"
                        v-model="rec.receptiveness_to_treatment"
                        label="Receptiveness to Treatment"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="substance_use_comments"
                        v-model="rec.substance_use_comments"
                        label="Substance Use Comments"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                </section>
                <section class="tabbed-page form_section_7" id="page-tab-7" v-disable-all="!pageMeta.permissions.edit">
                    <div class="avoid-break">
                        <h2>Social & Spiritual History</h2>
                        <h3>Social & Spiritual</h3>
                        <ExpandableTextArea
                            class="avoid-break block"
                            inputClass="block do-not-print-me"
                            oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                            type="text"
                            id="strengths_skills_positive_traits"
                            v-model="rec.strengths_skills_positive_traits"
                            label="Strengths (include skills, abilities, and positive traits)"
                            :limit=500
                            inputStyle="min-height:20px;"
                        />
                    </div>
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="current_support_network"
                        v-model="rec.current_support_network"
                        label="Current Support Network"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="current_relationships"
                        v-model="rec.current_relationships"
                        label="Description of Current Relationships"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="past_relationship_problems"
                        v-model="rec.past_relationship_problems"
                        label="Description of Any Past Relationship Problems"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="impact_of_substance_use_on_relationships"
                        v-model="rec.impact_of_substance_use_on_relationships"
                        label="Impact of Substance Use on Relationships"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="leisure_activities"
                        v-model="rec.leisure_activities"
                        label="Leisure Activities"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="impact_of_substance_use_on_leisure_activities"
                        v-model="rec.impact_of_substance_use_on_leisure_activities"
                        label="Impact of Substance Use on Leisure Activities"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="cultural_inﬂuences"
                        v-model="rec.cultural_inﬂuences"
                        label="Cultural Inﬂuences"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="spiritual_beliefs_and_practices"
                        v-model="rec.spiritual_beliefs_and_practices"
                        label="Spiritual Beliefs and Practices (include impact on functioning)"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="impact_of_substance_use_on_spiritual_beliefs"
                        v-model="rec.impact_of_substance_use_on_spiritual_beliefs"
                        label="Impact of Substance Use on Spiritual Beliefs"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="impact_of_substance_use_on_personal_values"
                        v-model="rec.impact_of_substance_use_on_personal_values"
                        label="Impact of Substance Use on Personal Values"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="social_and_spiritual_comments"
                        v-model="rec.social_and_spiritual_comments"
                        label="Social and Spiritual Comments"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                </section>
                <section class="tabbed-page form_section_8" id="page-tab-8" v-disable-all="!pageMeta.permissions.edit">
                    <div class="avoid-break">
                        <h2>Risk Assessment & Clinical Observations</h2>
                        <h3>Risk Assessment</h3>
                        <ExpandableTextArea
                            class="avoid-break block"
                            inputClass="block do-not-print-me"
                            oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                            type="text"
                            id="any_current_suicidal_ideation"
                            v-model="rec.any_current_suicidal_ideation"
                            label="Any Current Suicidal Ideation?"
                            :limit=500
                            inputStyle="min-height:20px;"
                        />
                    </div>
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="any_current_homicidal_ideation"
                        v-model="rec.any_current_homicidal_ideation"
                        label="Any Current Homicidal Ideation?"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="any_current_or_previous_self_injury"
                        v-model="rec.any_current_or_previous_self_injury"
                        label="Any Current or Previous Self Injury?"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="weapons_in_the_home"
                        v-model="rec.weapons_in_the_home"
                        label="Details of Any Handguns or Weapons in the Home"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="history_of_violent_or_aggressive_behavior"
                        v-model="rec.history_of_violent_or_aggressive_behavior"
                        label="History of Violent or Aggressive Behavior"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <div class="flex bottom avoid-break">
                        <Input
                            class=" flex-1 right-15 block bottom-10"
                            type="text"
                            id="is_client_currently_pregnant"
                            v-model="rec.is_client_currently_pregnant"
                            label="Is Client Currently Pregnant?"
                        />
                        <Input
                            class=" flex-1 block bottom-10"
                            type="text"
                            id="access_to_prenatal_care"
                            v-model="rec.access_to_prenatal_care"
                            label="If client is Currently Pregnant, Do They Have Access to Prenatal Care?"
                        />
                    </div>
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="history_of_risk_taking_or_impulsive_behaviour"
                        v-model="rec.history_of_risk_taking_or_impulsive_behaviour"
                        label="History of Risk Taking or Impulsive Behaviour"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <Input
                        class="block bottom-10 avoid-break"
                        type="text"
                        id="prior_hiv_testing"
                        v-model="rec.prior_hiv_testing"
                        label="Any Prior HIV Testing?"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="ability_to_care_for_self"
                        v-model="rec.ability_to_care_for_self"
                        label="Client's Ability to Care for Self"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="risk_assessment_comments"
                        v-model="rec.risk_assessment_comments"
                        label="Risk Assessment Comments"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />

                    <div class="avoid-break">
                        <h3>Clinical Observation</h3>
                        <ExpandableTextArea
                            class="avoid-break block"
                            inputClass="block do-not-print-me"
                            oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                            type="text"
                            id="appearance"
                            v-model="rec.appearance"
                            label="Appearance"
                            :limit=500
                            inputStyle="min-height:20px;"
                        />
                    </div>
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="behaviour"
                        v-model="rec.behaviour"
                        label="Behaviour"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="memory"
                        v-model="rec.memory"
                        label="Memory"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="orientation"
                        v-model="rec.orientation"
                        label="Orientation"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="insight_and_judgement"
                        v-model="rec.insight_and_judgement"
                        label="Insight and Judgement"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />

                    <div class="flex colx2 avoid-break">
                        <div class="flex-1">
                            <ExpandableTextArea
                                class="avoid-break block"
                                inputClass="block flex-1 do-not-print-me"
                                oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                                type="text"
                                id="eye_contact"
                                v-model="rec.eye_contact"
                                label="Eye Contact"
                                :limit=500
                                inputStyle="min-height:20px;"
                            />
                        </div>
                        <div class="flex-1 left-15">
                            <ExpandableTextArea
                                class="avoid-break block"
                                inputClass="block flex-1 do-not-print-me"
                                oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                                type="text"
                                id="speech"
                                v-model="rec.speech"
                                label="Speech"
                                :limit=500
                                inputStyle="min-height:20px;"
                            />
                        </div>
                    </div>
                    <div class="flex colx2 avoid-break">
                        <div class="flex-1">
                            <ExpandableTextArea
                                class="avoid-break block"
                                inputClass="block flex-1 do-not-print-me"
                                oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                                type="text"
                                id="mood"
                                v-model="rec.mood"
                                label="Mood"
                                :limit=500
                                inputStyle="min-height:20px;"
                            />
                        </div>
                        <div class="flex-1 left-15">
                            <ExpandableTextArea
                                class="avoid-break block"
                                inputClass="block flex-1 do-not-print-me"
                                oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                                type="text"
                                id="affect"
                                v-model="rec.affect"
                                label="Affect"
                                :limit=500
                                inputStyle="min-height:20px;"
                            />
                        </div>
                    </div>
                    <div class="flex colx2 avoid-break">
                        <div class="flex-1">
                            <ExpandableTextArea
                                class="avoid-break block"
                                inputClass="block flex-1 do-not-print-me"
                                oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                                type="text"
                                id="gait"
                                v-model="rec.gait"
                                label="Gait"
                                :limit=500
                                inputStyle="min-height:20px;"
                            />
                        </div>
                        <div class="flex-1 left-15">
                            <ExpandableTextArea
                                class="avoid-break block"
                                inputClass="block flex-1 do-not-print-me"
                                oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                                type="text"
                                id="motor_activity"
                                v-model="rec.motor_activity"
                                label="Motor Activity"
                                :limit=500
                                inputStyle="min-height:20px;"
                            />
                        </div>
                    </div>
                    <div class="flex colx2 avoid-break">
                        <Input class="block bottom-30 flex-1 right-15"

                            type="text"
                            id="hallucinations"
                            v-model="rec.hallucinations"
                            label="Any Hallucinations?"
                        />

                        <ExpandableTextArea
                            class="avoid-break block"
                            inputClass="block flex-1 do-not-print-me"
                            oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                            type="text"
                            id="hallucinations_types"
                            v-model="rec.hallucinations_types"
                            label="If Yes, What Type(s) of Hallucinations?"
                            :limit=500
                            inputStyle="min-height:20px;"
                        />
                    </div>

                    <div class="flex colx2 avoid-break">
                        <Input class="block bottom-30 flex-1 right-15" type="text" id="delusions" v-model="rec.delusions" label="Any Delusions?" />
                        <ExpandableTextArea
                            class="avoid-break block"
                            inputClass="block flex-1 do-not-print-me"
                            oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                            type="text"
                            id="delusions"
                            v-model="rec.delusions_types"
                            label="If Yes, What Type(s) of Delusions?"
                            :limit=500
                            inputStyle="min-height:20px;"
                        />
                    </div>

                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="ability_to_comprehend_the_material_presented"
                        v-model="rec.ability_to_comprehend_the_material_presented"
                        label="Client's Ability to Comprehend the Material Presented in the Assessment"
                        :limit=500
                        inputStyle="min-height:20px;"
                    />

                </section>
                <section class="tabbed-page form_section_9" id="page-tab-9" v-disable-all="!pageMeta.permissions.edit">
                    <div class="avoid-break">
                        <h2>Summary, Recommendations, Sign & Submit</h2>
                        <h3>Narrative Summary</h3>
                        <div class="flex center bottom-20">
                            <div>Collateral Information Reviewed?</div>
                            <Radios v-model="rec.collateral_information_reviewed" :options="['Yes', 'No']" />
                        </div>
                    </div>
                    <ExpandableTextArea
                        class="avoid-break block"
                        inputClass="margin-0 block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        id="biopsychosocial_assessment_summary"
                        v-model="rec.biopsychosocial_assessment_summary"
                        label="Biopsychosocial Assessment Summary Details"
                        :limit=4000
                    />
                    <div class="avoid-break">
                        <h3>Recommendations</h3>
                        <div class="bottom-10">
                            <ExpandableTextArea
                                class="avoid-break block"
                                inputClass="margin-0 block do-not-print-me"
                                oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                                type="text"
                                id="recommendation"
                                v-model="rec.recommendation"
                                label="Recommendation Details (include level of care)"
                                :limit=4000
                            />
                        </div>
                    </div>
                </section>

                <!-- put generated code ABOVE here -->

                <section class="flex space-between top-20">
                    <!-- SIGNATURE -->
                    <div class="signing">
                        <FormSignature
                            :user_id="user_id"
                            :signature_ids="signature_ids"
                            ref="form_signatures"
                            @getSignatures="getSignatures"
                            v-if="signature_ids.length > 0"
                            :disabled="!pageMeta.permissions.edit"
                        />
                        <FormSignatureButton
                            :rec="rec"
                            :original_rec="original_rec"
                            :signatures_info="signatures_info"
                            :fileName="pageMeta.component_name"
                            :signature_ids="signature_ids"
                            :record_id="record_id"
                            @resetView="reloadData"
                            @fail="fail2sign"
                            @showModal="showPdfModal"
                            :save="updateRecord"
                            :disabled="!pageMeta.permissions.edit"
                        />
                    </div>

                    <div class="do-not-print-me">
                        <!-- NEXT / PREVIOUS -->
                        <div class="align-right bottom-15">
                            <button class="secondary" type="button" @click="goToPreviousSection" v-if="showPrevBtn">
                                <span class="material-icons-outlined">west</span> Previous Section
                            </button>
                            <button class="secondary" type="button" @click="goToNextSection" v-if="showNextBtn">
                                Next Section <span class="material-icons-outlined">east</span>
                            </button>
                        </div>
                        <!-- SAVE AS DRAFT / CANCEL EDIT-->
                        <div class="align-right" v-if="!signature_ids.length">
                            <button
                                v-if="original_signature_ids.length && !signature_ids.length"
                                class="align-right inline-block secondary right-15"
                                type="button"
                                @click="signature_ids = original_signature_ids"
                            >Cancel Edit</button>
                            <ConfirmButton class="inline-block" @click="updateRecord()" :canedit="isEditable"  :disabled="!pageMeta.permissions.edit">Save as Draft</ConfirmButton>
                        </div>
                    </div>
                </section>                
            </form>
            <VIfWorkableModal
                :hasCloseButton="false"
                data-html2canvas-ignore="true"
                v-if="showCreatePdfModal"
                v-disable-all="!pageMeta.permissions.edit"
            >
                <div>
                    <SignConvertPdfModal />
                </div>
            </VIfWorkableModal>
        </div>
    </div>
</template>
<script>
    /**
     * As Josh suggested, I could bring up a full screen modal that will appear once a user presses "Save and Sign." this will then bring up a modal that says "Saving..." that will be ignored"
     */
     import SignConvertPdfModal from '@/components/formpieces/SignConvertPdfModal'
    import { clients, companies, offices } from '@/util/apiRequests';
    import FormClient from '@/components/formpieces/FormClient';
    import FormClientFull from '@/components/formpieces/FormClientFull';
    import FormCompany from '@/components/formpieces/FormCompany.vue';
    import FormSignature from '@/components/formpieces/FormSignatureNew';
    import FormSignatureButton from '@/components/formpieces/FormSignatureButtonNew';
    import VIfWorkableModal from '@/components/general/modals/vIfWorkableModal.vue';
    import { dryalex } from '@/mixins/dryalex';

    import { merge } from 'lodash';
    import dayjs from 'dayjs';

    export default {
        name: 'BioPyschoSocial',
        props: {
            record_id: {
                type: [String, Number],
                required: false,
                default: 0
            },
            puppet: {
                type: [String],
                required: false,
                default: ''
            }
        },
        components: { FormClient, FormClientFull, FormSignature, VIfWorkableModal, FormCompany, FormSignatureButton, SignConvertPdfModal },
        data() {
            return {
                loading: 1,
                updating: 0,
                isEditable: 1,
                user_id: 0,
                client_id: 0,
                page_id: 0,
                rec: {},
                original_rec: {},
                signature_ids: [],
                original_signature_ids: [],
                showCreatePdfModal: false,
                api_signs_needed: null,
                signatures_info: null,
                client: {},
                company: {},
                rows: [],
                level_id: 0,
                level: 'company',
                options: [],
                this_record_id: 0,
                router_push: 0,
                counselorOptions: [],
                locationOptions: [],
                showNextBtn: 1,
                showPrevBtn: 0,
                dateEditing: false,
                emergencyContacts: null,
                opts: {
                    highest_level_of_education: [
                        'Some High School',
                        'High School Diploma or GED',
                        'Technical or Occupation Training',
                        'Assosciates Degree',
                        'Some College',
                        "Bachelor's Degree",
                        'Masters Degree',
                        'Doctorate'
                    ],
                    sexual_orientation: [
                        'Asexual',
                        'Bisexual',
                        'Gay',
                        'Lesbian',
                        'Pansexual/Fluid',
                        'Questioning',
                        'Heterosexual'
                    ],
                    employment_status: ['Employed', 'Self Employed', 'Unemployed', 'Retired']
                }
            };
        },
        methods: {
            async fail2sign() {
                this.showPdfModal(false);
            },
            async reloadData(signature) {
                this.showCreatePdfModal = false;
                this.signature_ids.push(signature);
            },
            showPdfModal(bool) {
                this.showCreatePdfModal = bool;
            },
            getClient(data) {
                this.client = data;
            },
            getCompany(data) {
                this.company = data;
            },
            async getCounselorOptions() {
                try {
                    let result = await this.$api.get(companies.getClinicians(this.$store.state.user.company_id));
                    this.counselorOptions = result.data.map((element) => {
                        return { text: `${element?.first_name} ${element?.last_name}`, value: element.id };
                    });
                    this.counselorOptionsOriginal = this.counselorOptions;
                } catch (err) {
                    this.$toasted.error('Could not retrieve list of counselors.');
                }
            },
            async getOffices() {
                try {
                    const res = await this.$api.post(offices.getOffices(), { criteria: {} });
                    this.locationOptions = res.data.rows;
                } catch (err) {
                    this.$toasted.error('Could not retrieve list of offices');
                }
            },

            //signatures that come from the FormSignatures component
            getSignatures(data) {
                this.signatures_info = data ? data : [];
            },
            async updateRecord(e) {
                if (!this.updating) {
                    this.updating = 1;
                    try {
                        e.preventDefault();
                    } catch (error) {
                        /**/
                    }

                    let xthis = await dryalex.form_data_update_record(this, e);
                    merge(this, xthis);
                    if (this.router_push) {
                        this.router_push = 1;
                        this.$forceUpdate();
                    }
                    this.original_signature_ids = this.signature_ids;
                }
            },
            async init() {
                await Promise.all([this.getCounselorOptions(), this.getOffices()]);
                let xthis = await dryalex.form_data_init(this);
                merge(this, xthis);
                if (!this.rec.date) this.rec.date = new Date();
                this.loading = 0;
            },

            toggleDateEditing() {
                this.dateEditing = !this.dateEditing;
            },

            async changeTab(e) {
                const tabNumber = parseInt(e.target.id.match(/\d+/)[0], 10);

                document.querySelector('input[name=page-tab-input][checked="checked"]').setAttribute('checked', false);

                document.querySelector(`#${e.target.id}`).setAttribute('checked', 'checked');

                this.showNextBtn = 1;
                this.showPrevBtn = 1;

                const all = document.querySelectorAll('input[name=page-tab-input]');
                const numberOfTabs = all.length;

                if (tabNumber === 1) {
                    this.showPrevBtn = 0;
                } else if (tabNumber === numberOfTabs) {
                    this.showNextBtn = 0;
                }
            },
            async show_all_tabs4pdf() {
                document.querySelectorAll('.do-not-print-me').forEach((e) => (e.style.display = 'none'));
                document
                    .querySelectorAll('div.rec-data-div form section.tabbed-page')
                    .forEach((e) => (e.style.display = 'block'));
                document.querySelectorAll('input[name=page-tab-input]').forEach((e) => e.setAttribute('checked', true));
            },

            goToNextSection() {
                this.showPrevBtn = 1;
                const forRange = document.querySelectorAll('input[name=page-tab-input]');
                const max = forRange.length;
                const selected = document.querySelector('input[name=page-tab-input][checked="checked"]');
                const selectedTab = parseInt(selected.id.match(/\d+/)[0]);
                if (selectedTab < max) {
                    const nextTab = document.querySelector(
                        `input[name=page-tab-input][id=page-tab-${selectedTab + 1}]`
                    );
                    nextTab.click();
                }
                if (selectedTab + 1 === max) {
                    this.showNextBtn = 0;
                }
            },
            async goToPreviousSection() {
                this.showNextBtn = 1;
                const selected = document.querySelector('input[name=page-tab-input][checked = "checked"]');
                const selectedTab = parseInt(selected.id.match(/\d+/)[0], 10);
                if (selectedTab !== 1) {
                    const previousTab = document.querySelector(
                        `input[name=page-tab-input][id=page-tab-${selectedTab - 1}]`
                    );
                    previousTab.click();
                }
                if (selectedTab - 1 === 0) {
                    this.showPrevBtn = 0;
                }
            },
            printView() {
                window.print();
            },
        },
        computed: {
            dateDate: {
                get() {
                    return dayjs(this.rec.date).format('YYYY-MM-DD');
                },
                set(value) {
                    const newDate = dayjs(value);
                    const oldDate = dayjs(this.rec.date);
                    this.rec.date = newDate
                        .set('hour', oldDate.hour())
                        .set('minute', oldDate.minute())
                        .toDate();
                }
            },
            dateTime: {
                get() {
                    return dayjs(this.rec.date).format('HH:mm');
                },
                set(value) {
                    const newDate = dayjs(this.rec.date);
                    this.rec.date = newDate
                        .set('hour', value.split(':')[0])
                        .set('minute', value.split(':')[1])
                        .toDate();
                }
            },
            formattedDate() {
                return dayjs(this.rec.date).format('YYYY-MM-DD hh:mm A');
            }
        },
        async created() {
            await this.init();
            if (this.puppet == 'pdf') {
                await this.show_all_tabs4pdf();
            }
        },
        watch: {
            showCreatePdfModal(newVal) {
                if (window.innerWidth >= 768 && newVal == true) this.openModalOverlay();
                if (newVal == false) {
                    this.closeModalOverlay();
                }
            }
        }
    };
</script>
